@mixin breakpoint($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

.header {
  background: #fbecef;
  padding: 12px 0;
  border: 1px solid #ededed;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @include breakpoint(768px) {
      gap: 4px;
    }
  }
  &__logo-img {
  }
  &__logo-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;

    letter-spacing: -0.04em;

    color: #1e222e;
    padding-top: 6px;
    user-select: none;

    @include breakpoint(768px) {
      font-size: 20px;
      line-height: 18px;
    }
  }

  &__link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #ffffff;

    padding: 8px 18px 10px 18px;
    background: #397ce8;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      background: #4c89ea;
    }
  }
}
