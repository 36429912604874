@import "mixins";
@import "vars";
@import "vendor/reset";

.container {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;

  @include breakpoint(358px) {
    padding: 0 12px;
  }
}

html {
  font-size: 16px;
}

body {
  font-family: "Inter", sans-serif;

  background: #f2f2f2;

  overflow-x: hidden;

  &._lock {
    overflow: hidden;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.loader {
  animation: spinning 2s linear infinite;

  margin: 0 auto;
  padding: 50px;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.boxs {
  display: flex;
  flex-direction: column;
}
.box {
}
.box:nth-child(1) {
  order: -4;
}

.box:nth-child(2) {
  order: -3;
}
.box:nth-child(3) {
  order: -2;
}
.boxf {
  order: -1;
}

.subscribe-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  // padding: 16px;
  background: #eef5fc;

  display: none;
  justify-content: space-between;
  padding: 16px;
  gap: 20px;

  @include breakpoint(768px) {
    display: flex;
  }

  &__left {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* black text */

    color: #1e222e;

    @include breakpoint(428px) {
      max-width: 170px;
    }
  }

  &__btn {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 14px 10px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(111, 111, 111, 0.05);
    border-radius: 8px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    letter-spacing: -0.03em;

    /* black text */

    color: #1e222e;
    transition: all 0.3s ease;
    & > img {
      width: 18px;
      height: auto;
    }
    &:hover {
      box-shadow: 0px 3px 10px rgba(111, 111, 111, 0.12);
    }
  }
}

.hr {
  height: 1px;
  background: #d7d7d7;
  width: 100%;
}

/* Blocks */

@import "blocks/header";

@import "blocks/main";

@import "blocks/jobs";

@import "blocks/footer";
