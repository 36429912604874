.footer {
  background: #eef5fc;
  padding: 12px 0;

  margin-top: 150px;

  @include breakpoint(768px) {
    margin-bottom: 72px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @include breakpoint(768px) {
      gap: 4px;
    }
  }
  &__logo-img {
  }
  &__logo-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;

    letter-spacing: -0.04em;

    color: #1e222e;
    // padding-top: 6px;
    user-select: none;

    @include breakpoint(768px) {
      font-size: 20px;
      line-height: 18px;
    }
    @include breakpoint(428px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    /* secondary text */

    color: #7d8295;

    @include breakpoint(768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
